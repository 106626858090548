import React, { memo } from 'react';
import Box from '@mui/material/Box';
import { Form, Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Card from '../../../../components/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import mova from 'mova';
import FormikTextInput from '../../../../form/FormikTextInput';
import FormikCheckbox from '../../../../form/FormikCheckbox';
import Button from '../../../../components/Button/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Icon from '../../../../components/Icon/Icon';
import { useTheme } from '@mui/material';
import { locales } from "utils/i18nUtils";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const t = mova.ns('components.BookingWidget');

const defaultTranslations = locales.reduce((acc, locale) => {
  acc[locale] = { pageTitle: '', policyTitle: '', policyText: '', policyItems: [''], finishTitle: '', finishMessage: '' };
  return acc;
}, {});

const BookingWidget = ({ showToastr }) => {
  const dispatch = useDispatch();

  const { id, reservationUi, organisations } = useSelector(placeSelectors.getActivePlace()) || {};
  const showOrganisationSettings = !!organisations.length;
  const organisationIds = organisations.map(o => o.id);
  const allPlaces = useSelector(placeSelectors.getPlaces());
  const organisationPlaces = allPlaces.filter(p => p.organisations.some(o => organisationIds.includes(o.id)));

  const config = {
    ...(reservationUi?.styles || {}),
    ...(reservationUi?.settings || {}),
  };
  const theme = useTheme();

  const updateOrganisation = async values => {
    await dispatch(
      await placeThunks.saveReservationUi({
        placeId: id,
        styles: reservationUi?.styles || {},
        settings: { ...(reservationUi?.settings || {}), ...values },
      }),
    );
    await showToastr(t('success'));
  };

  const updateStyles = async values => {
    await dispatch(
      await placeThunks.saveReservationUi({
        placeId: id,
        styles: values,
        settings: reservationUi?.settings || {},
      }),
    );
    await showToastr(t('success'));
  };

  const updateConfig = async values => {
    await dispatch(
      await placeThunks.saveReservationUi({
        placeId: id,
        settings: values,
        styles: reservationUi?.styles || {},
      }),
    );
    await showToastr(t('success'));
  };

  const styleFields = [
    'backgroundColor',
    'borderColor',
    'primaryColor',
    'primaryColorHover',
    'textColor',
    'contrastTextColor',
    'errorColor',
  ];

  const addPolicyItem = (locale, formik) => {
    formik.setFieldValue(`translations.${locale}.policyItems`, [...formik.values.translations[locale].policyItems, '']);
  };

  const removePolicyItem = (index, locale, formik) => {
    formik.setFieldValue(
      `translations.${locale}.policyItems`,
      formik.values.translations[locale].policyItems.filter((_, i) => index !== i),
    );
  };

  const toggleOrganisationPlace = (formikContext, id) => {
    if (formikContext.values.organisationPlaces.includes(id)) {
      formikContext.setFieldValue('organisationPlaces', formikContext.values.organisationPlaces.filter(val => val !== id));
    } else {
      formikContext.setFieldValue('organisationPlaces', [...formikContext.values.organisationPlaces, id]);
    }
  }

  return (
    <Box>
      {showOrganisationSettings && (
        <Card sx={{ mb: 2 }}>
          <Formik
            initialValues={{
              organisationPlaces: reservationUi.settings?.organisationPlaces || [],
            }}
            onSubmit={updateOrganisation}
          >
            {formikContext => (
              <Form>
                <Typography variant='h3' sx={{ mb: 2 }}>
                  {t('organisationTitle')}
                </Typography>
                <Box display='flex' flexDirection='column' gap={1}>
                  {organisationPlaces.map(place => (
                    <FormControlLabel
                      key={place.id}
                      label={place.name}
                      control={
                        <Checkbox
                          onChange={() => toggleOrganisationPlace(formikContext, place.id)}
                          color='error'
                          checked={formikContext.values.organisationPlaces.includes(place.id)}
                        />
                      }
                    />
                  ))}
                  <Button type='submit' size='large' sx={{ mt: 2, alignSelf: 'center' }}>
                    {t('save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card>
      )}
      <Card>
        <Formik
          initialValues={{
            backgroundColor: config.backgroundColor || '#fff',
            borderColor: config.borderColor || '#d3d3d3',
            primaryColor: config.primaryColor || '#293d33',
            primaryColorHover: config.primaryColorHover || '#90c29a',
            textColor: config.textColor || '#303633',
            contrastTextColor: config.contrastTextColor || '#fff',
            errorColor: config.errorColor || '#FF4D4F',
          }}
          onSubmit={updateStyles}
        >
          {formikContext => (
            <Form>
              <Typography variant='h3' sx={{ mb: 2 }}>
                {t('styleTitle')}
              </Typography>
              <Box display='flex' flexDirection='column' gap={1}>
                {styleFields.map(field => (
                  <Box key={field} display='flex' alignItems='center' gap={2}>
                    <FormikTextInput name={field} sx={{ width: 100 }} />
                    <Box
                      width={24}
                      height={24}
                      border={1}
                      borderColor='typography.primary'
                      backgroundColor={formikContext.values[field]}
                    />
                    <Typography>{t(field)}</Typography>
                  </Box>
                ))}
                <Button type='submit' size='large' sx={{ mt: 2, alignSelf: 'center' }}>
                  {t('save')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
      <Card sx={{ mt: 2 }}>
        <Formik
          initialValues={{
            showSlots: config.showSlots || false,
            showHalls: config.showHalls || false,
            translations: config.translations || defaultTranslations,
          }}
          onSubmit={updateConfig}
        >
          {formikContext => (
            <Form>
              <Typography variant='h3' sx={{ mb: 2 }}>
                {t('configTitle')}
              </Typography>
              <Box display='flex' flexDirection='column' gap={1}>
                <FormikCheckbox name='showSlots' label={t('showSlots')} />
                <FormikCheckbox name='showHalls' label={t('showHalls')} />
                <Typography variant='h3' sx={{ mb: 2 }}>
                  {t('translations')}:
                </Typography>
                {locales.map(locale => (
                  <Card key={locale}>
                    <Typography variant='h4' sx={{ textAlign: 'center', mb: 2 }}>
                      {t(`lang.${locale}`)}
                    </Typography>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ verticalAlign: 'middle' }} variant='body2'>
                          {t('pageTitle')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormikTextInput name={`translations.${locale}.pageTitle`} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ verticalAlign: 'middle' }} variant='body2'>
                          {t('policyTitle')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormikTextInput name={`translations.${locale}.policyTitle`} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ verticalAlign: 'middle' }} variant='body2'>
                          {t('policyText')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormikTextInput name={`translations.${locale}.policyText`} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ verticalAlign: 'middle' }} variant='body2'>
                          {t('policyItems')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} display='flex' flexDirection='column' gap={1}>
                        {formikContext.values.translations[locale].policyItems.map((item, index) => (
                          <Box display='flex' alignItems='center' gap={1} key={index}>
                            <FormikTextInput name={`translations.${locale}.policyItems[${index}]`} fullWidth />
                            {index === 0 && (
                              <IconButton color='primary' onClick={() => addPolicyItem(locale, formikContext)}>
                                <Icon type='plus' color={theme.palette.typography.primary} />
                              </IconButton>
                            )}
                            {index > 0 && (
                              <IconButton onClick={() => removePolicyItem(index, locale, formikContext)}>
                                <Icon type='remove' />
                              </IconButton>
                            )}
                          </Box>
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ verticalAlign: 'middle' }} variant='body2'>{t('finishTitle')}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormikTextInput name={`translations.${locale}.finishTitle`} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ verticalAlign: 'middle' }} variant='body2'>{t('finishMessage')}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormikTextInput name={`translations.${locale}.finishMessage`} fullWidth />
                      </Grid>
                    </Grid>
                  </Card>
                ))}
                <Button type='submit' size='large' sx={{ mt: 2, alignSelf: 'center' }}>
                  {t('save')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Box>
  );
};

export default memo(BookingWidget);
