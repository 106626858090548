export default {
  base: {
    cancel: 'Отменить',
    save: 'Сохранить',
    confirm: 'Подтвердить',
    close: 'Закрыть',
    search: 'Поиск',
    login: 'Войти',
    register: 'Зарегистрироваться',
    submit: 'Отправить',
    edit: 'Редактировать',
    delete: 'Удалить',
    city: 'Город',
    seeAll: 'Посмотреть все',
    length: 'Длина',
    width: 'Ширина',
    height: 'Высота',
    unknownUser: 'Гость',
    sendAgain: 'Отправить снова',
  },
  lang: {
    ua: 'Украинский',
    ru: 'Русский',
    en: 'Английский',
    es: 'Испанский',
  },
  currencies: {
    uah: 'грн'
  },
  types: {
    user: {
      phone: 'Телефон',
      email: 'Электронный адрес',
      password: 'Пароль',
      oldPassword: 'Старый пароль',
      newPassword: 'Новый пароль',
      confirmPassword: 'Подтверждение пароля',
      firstName: 'Имя',
      lastName: 'Фамилия',
      confirm: '<base.confirm>',
      avgBillAmount: 'Средний чек',
      restaurant: 'Ресторан',
      city: '<base.city>',
      lang: 'Язык общения',
    },
    admin: {
      '@extends': 'types.user',
      lastLogin: 'Последний раз в сети',
      role: 'Роль',
      roles: {
        admin: 'Администратор',
        analyst: 'Аналитик',
        owner: 'Владелец',
      },
    },
    place: {
      name: 'Название',
      description: 'Описание',
      address: 'Адрес',
      latitude: 'Широта',
      longitude: 'Долгота',
      active: 'Активный',
      link: 'Ссылка',
      cusines: 'Кухня',
      moods: 'Настроения',
      payments: 'Платежи',
      city: '<base.city>',
      web: 'Веб-сайт',
      phone: 'Телефон',
      time: 'Время',
      contactPhone: 'Телефон для уведомлений',
      averageBill: 'Средний чек',
    },
    placeOpenHours: {
      sun: 'Воскресенье',
      mon: 'Понедельник',
      tue: 'Вторник',
      wed: 'Среда',
      thu: 'Четверг',
      fri: 'Пятница',
      sat: 'Суббота',
    },
    placeHall: {
      name: 'Название',
      active: 'Активный',
      length: '<base.length> (м)',
      width: '<base.width> (м)',
      order: 'Порядок',
    },
    placeSpot: {
      type: 'Тип',
      label: 'Название',
      seats: 'Кол-во Мест',
      minSeats: 'Минимальное кол-во мест',
      length: '<base.length> (м)',
      width: '<base.width> (м)',
      priority: 'Приоритет',
      angle: 'Угол',
      oneSeat: 'Одноместный',
      twoSeat: 'Двухместный',
      border: 'Граница',
      depositCategory: 'Категория депозита',
      borderType: {
        solid: 'Сплошная',
        dashed: 'Пунктирная',
        dotted: 'Точки',
      },
    },
    cities: {
      kyiv: 'Киев',
      odessa: 'Одесса',
    },
    reservation: {
      accepted: 'Одобренные',
      requested: 'Новые',
      success: 'Успешные',
      rejected: 'Отклоненные',
      cancelled: 'Отмененные',
      skipped: 'Пропущенные',
      finished: 'Законченные',
      waitlist: 'Список ожидания',
      inProgress: 'В процессе',
      totalReserves: 'Всего резервов',
      successReservations: 'Успешные резервы',
      failedReservations: 'Неуспешные резервы',
      comment: 'Комментарий',
      hall: 'Зал',
      spot: 'Стол',
      spots: 'Столы',
      from: 'Начало',
      to: 'Конец',
      seats: 'Количество человек',
      event: 'Мероприятие',
      events: {
        other: 'Другое',
        business: 'Деловая встреча',
        date: 'Свидание',
        family: 'Семейный отдых',
        friends: 'Встреча с друзьями',
        birthday: 'День рождения',
        football: 'Футбол',
      },
      keepTable: 'Не пересаживать',
      manager: 'Администратор',
      waitForTomorrow: 'Переносить на следующий день',
    },
    dish: {
      name: 'Название блюда',
      categories: 'Категории',
      photo: 'Фото',
      description: 'Описание',
      weight: 'Вес',
      price: 'Цена, грн',
      active: 'Активное',
      vegetarian: 'Вегетарианское',
      hot: 'Острое',
      top: 'Топ',
    },
    dishCategory: {
      name: 'Название категории',
      parent: 'Родительская категория',
      order: 'Порядок',
    },
    legal: {
      privacy: 'Политика конфиденциальности',
      privacyWhat: 'Политику конфиденциальности',
      terms: 'Условия использования',
      termsWhat: 'Условия использования',
    },
    channel: {
      name: 'Имя',
    },
    certificate: {
      active: 'Активный',
      name: 'Название',
      category: 'Категория',
      categoryCertificate: 'Сертификат',
      categorySubscription: 'Абонемент',
      price: 'Цена',
      description: 'Описание',
      photo: 'Фото',
      type: 'Тип',
      typeVisits: 'На количество посещений',
      typeAmount: 'На сумму',
      visits: 'Количество посещений',
      amount: 'Сумма',
      expiration: 'Срок действия',
      expirationDate: 'До определенной даты',
      expirationDays: 'Дней с момента покупки',
      tillDate: 'До какой даты',
      validDays: 'Количество дней',
      activation: 'Дата активации',
      activationPurchase: 'С момента покупки',
      activationVisit: 'После первого посещения',
      activationVisitMaxDays: 'Автоматически активировать через (дней)',
      duration: 'Срок действия абонемента (дней)',
      id: 'Уникальный номер',
      clientName: 'Имя гостя',
      clientLastName: 'Фамилия гостя',
      phone: 'Телефон',
      status: 'Статус',
      purchased: 'Дата покупки',
      comment: 'Комментарий',
      statuses: {
        DRAFT: 'Не оплаченный',
        INACTIVE: 'Не активный',
        ACTIVE: 'Активный',
        USED: 'Использованный',
        EXPIRED: 'Завершенный',
      }
    },
  },
  auth: {
    Login: {
      fields: { '@extends': 'types.user' },
      forgotPassword: 'Забыли Пароль?',
      title: 'Вход',
      errors: {
        '@any': 'Произошла ошибка!',
        '@extends': 'errors.validation',
        wrongAuth: 'Неправильный телефон или пароль',
        wrongRole: 'У Вас нет прав доступа к этой услуге. Свяжитесь с администратором',
        agreeLegal: 'Вы должны согласиться с данными условиями',
      },
      submit: '<base.login>',
      dontHaveAccount: 'Нет учетной записи?',
      createAccount: 'Создать учетную запись',
      rememberMe: 'Запомнить меня',
      or: 'или',
      agreeLegal: 'Я соглашаюсь с',
      legal: 'Правилами информационного взаимодействия с Сервисом Reservble',
    },
    Register: {
      fields: { '@extends': 'types.user' },
      legal: { '@extends': 'types.legal' },
      cities: { '@extends': 'types.cities' },
      title: 'Регистрация',
      errors: {
        '@any': 'Произошла ошибка!',
        '@extends': 'errors.validation',
        confirm: 'Вы должны принять условия',
      },
      haveAccount: 'Уже зарегистрированы?',
      confirm: 'Я принимаю',
      and: 'и',
      submit: '<base.register>',
      login: '<base.login>',
    },
    Social: {
      signIn: 'Войти с помощью',
      providers: {
        google: 'Google',
        facebook: 'Facebook',
      },
    },
    Forgot: {
      fields: { '@extends': 'types.user' },
      errors: {
        '@any': 'Произошла ошибка!',
        '@extends': 'errors.validation',
        userWithSpecifiedEmailNotFoundd: 'Аккаунт с такой почтой не найден',
      },
      login: '<base.login>',
      title: 'Восстановление пароля',
      buttonSend: 'Отправить инструкцию на почту',
      goBackLogin: 'Вернуться для авторизации',
    },
  },
  errors: {
    validation: {
      '@root': 'Неправильный формат',
      '@any': '<.>',
      format: '<.>',
      required: 'Это поле является обязательным',
      password: 'Введенный пароль слишком короткий',
      email: 'Пожалуйста, используйте правильный формат электронной почты',
      phone: 'Пожалуйста, используйте правильный формат телефона: +380999999999',
      minLength: 'Минимальная длина <=symbols> символов',
      maxLength: 'Максимальная длина <=symbols> символов',
      number: 'Необходимо ввести число',
      minNumber: 'Минимальное значение <=min>',
      maxNumber: 'Максимальное значение <=max>',
      validationError: 'Входной набор данных не является правильным в полях: <=fields>',
      notUniq: 'Такое название уже существует',
      url: 'Ссылка должна быть валидным URL адресом',
      passwordsMatch: 'Пароли не совпадают',
    },
    badRequest: 'Попробуйте еще раз или напишите администратору',
    socialUserAlreadyExist: 'Пользователь уже прикреплен к другому аккаунту',
    global: {
      somethingWrong: 'Что-то пошло не так',
      refresh:
        'Обновите страницу, и если проблема не исчезнет, свяжитесь с нашей командой по адресу nosenkocc@gmail.com',
    },
    menuHasDishes: 'Нельзя удалять категории, которые содержат блюда',
    menuHasChild: 'Нельзя удалять категории с подкатегориями',
  },
  components: {
    Select: {
      selectAll: 'Выбрать все',
    },
    Sidebar: {
      superadmin: 'Суперадмин',
      home: 'Главная',
      reservations: 'Резервы',
      timeline: 'Таймлайн',
      clients: 'Гости',
      halls: 'Залы',
      channels: 'Каналы продаж',
      menu: 'Меню',
      certificates: 'Сертификаты',
      reviews: 'Отзывы',
      statistics: 'Статистика',
      payment: 'Оплата',
      incomingPayments: 'Прием платежей',
    },
    DashboardMenu: {
      reservation: 'Бронирования',
      channels: 'Каналы продаж',
      top10: 'Топ-10',
      reviews: 'Отзывы',
    },
    Reservations: {
      title: 'Резервы',
      hall: 'Зал',
      table: 'Стол',
      accept: 'Подтвердить',
      reject: 'Отказать',
      rejectReasonTitle: 'Укажите причину отказа',
      rejectReason: 'Причина отказа',
      rejectNoPlacesShort: 'Вставить текст: Нет свободных мест',
      rejectNoPlaces: 'В данный момент на выбранное Вами время нет свободных мест',
      customDeclineMsg: 'Вставить собственное сообщение',
      confirm: 'Отказать',
      finish: 'Закончить',
      finishingReservation: 'Успешное завершение резерва',
      confirmFinishReservation: 'Вы уверены, что хотите отметить данный резерв как успешно выполненный?',
      billAmount: 'Сумма чека',
      indicateBillAmount: 'Укажите сумму чека',
      skip: 'Пропущено',
      skippingReservation: 'Резерв был пропущен',
      confirmSkipReservation: 'Вы уверены, что хотите отметить данный резерв как пропущенный гостем?',
      updateBillAmount: 'Редактировать сумму чека',
      updatingBill: 'Редактирование суммы чека',
      showHistory: 'Показать историю изменений',
      clientCancelled: 'Гость отменил резерв',
      cancellingReservation: 'Отмена резерва',
      confirmCancelReservation:
        'Вы уверены, что хотите отменить данный резерв от имени гостя? Это нужно делать только в том случае, если гость сам попросил отменить резерв.',
      movingToInProgress: 'Гость пришел в заведение',
      backToAccepted: 'Назад в подтвержденные',
      confirmBackToAccepted: 'Вы уверены, что хотите переместить этот резерв назад в список подтвержденных?',
      confirmMoveToInProgress: 'Вы подтверждаете, что гость пришел в ресторан?',
      additionalConfirmation: 'Подтвердить резерв',
      confirmAdditionalConfirmation: 'Вы уверены, что хотите подтвердить данный резерв?',
      deleteFromWaitList: 'Удалить из списка ожидания',
      confirmDeleteFromWaitList: 'Вы уверены, что хотите удалить данную запись из списка ожидания?',
      errors: { '@extends': 'errors.validation' },
      filter: { '@extends': 'types.reservation' },
      seeAll: '<base.seeAll>',
      acceptingReservation: 'Подтверждение резерва',
      confirmAcceptReservation: 'Вы уверены, что хотите подтвердить данный резерв?',
      today: 'Сегодня',
      tomorrow: 'Завтра',
      people: 'чел.',
      callClient: 'Позвонить гостю',
      disableDay: 'Закрыть бронирования',
      enableDay: 'Открыть бронирования',
      disablingDay: 'Закрытие бронирования',
      enablingDay: 'Открытие бронирования',
      showOnlyUnconfirmed: 'Показать только неподтвержденные',
      onlyThisHall: 'Резервы только в этом зале',
      confirmDisableDay:
        'Вы уверены, что хотите закрыть возможность бронирования для текущего дня? Гости больше не смогут забронировать столик на сегодня, пока вы вручную не отключите эту опцию. Данная настройка будет автоматически выключена завтра утром.',
      confirmEnableDay:
        'Вы уверены, что хотите открыть возможность бронирования для текущего дня? Гости снова смогут бронировать столик на сегодня.',
      dayClosedWarning:
        'Возможность бронирования была отключена для текущего дня. Новые гости не смогут сделать резервы на сегодня.',
      comment: 'Комментарий: ',
      cancelReason: 'Причина отмены: ',
      cancelReasons: {
        changeOfPlans: 'Изменились планы',
        mistake: 'Бронирование было создано по ошибке',
        late: 'Не успеваю на указанное время',
        changeTime: 'Хочу выбрать другое время',
        adminCancelled: 'Администратор отменил резерв по просьбе гостя',
      },
      unknownUser: '<base.unknownUser>',
      indicateHallAndTable: 'Укажите зал и стол',
      edit: '<base.edit>',
      pickSpot: 'Подобрать стол',
      spotNotFound: 'Свободный стол на выбранное время не найден',
      events: { '@extends': 'types.reservation.events' },
      minutes: 'м',
      hours: 'ч',
      uah: '<currencies.uah>',
      viewMenu: 'Посмотреть меню',
    },
    Channels: {
      title: 'Каналы продаж',
      noChannels: 'Нет каналов продаж',
      copyButtonLabel: 'Скопировать',
      promoLabel: 'Промо акция',
      activeLabel: 'Активировано',
      name: 'Название',
      successReservations: 'Успешные',
      unfinishedReservations: 'Незаконченные',
      addChannel: 'Добавить новый канал',
      update: 'Обновить канал',
      copiedSuccessfully: 'Ссылка скопирована в буфер обмена',
      seeAll: '<base.seeAll>',
      addNewChannel: 'Добавление канала продаж',
      fields: { '@extends': 'types.place' },
      errors: { '@extends': 'errors.validation' },
      deletingChannel: 'Удаление канала продаж',
      confirmDeleteChannel: 'Вы уверены, что хотите удалить данный канал продаж?',
      system: {
        placeReservations: {
          name: 'Администратор',
          description: 'Резервы, созданные вручную',
        },
        guestReservations: {
          name: 'Reservble Marketplace',
          description: 'Резервы',
        },
      },
    },
    ChannelStatistics: {
      '@extends': 'types.reservation',
      backToChannels: 'Назад к списку',
      newUsers: 'Новые пользователи',
      chartTitle: 'Статистика канала продаж',
    },
    Top10: {
      attendanceTitle: 'ТОП-10 Посещение',
      attendanceLabel: 'Посещений',
      checkTitle: 'ТОП-10 Чек',
      checkLabel: 'Средний чек',
      noData: 'Недостаточно данных для отображения статистики',
    },
    Settings: {
      settings: 'Настройки',
      description: 'Описание',
      characteristics: 'Характеристика',
      reservations: 'Резервы',
      gallery: 'Галерея',
      hours: 'Часы работы',
      contacts: 'Контакты',
      administration: 'Администрация',
      halls: 'Залы',
      integrations: 'Интеграции',
      deposits: 'Депозиты',
      bookingWidget: 'Виджет бронирования',
      promo: 'Промоакции',
      certificates: 'Сертификаты',
      fields: { '@extends': 'types.place' },
      hourFields: { '@extends': 'types.placeOpenHours' },
      inactive: 'Неактивный',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.settings' },
      cancel: '<base.cancel>',
      save: '<base.save>',
      contactForm: {
        fields: {
          type: 'Тип',
          reference: 'Значение',
          referencePlaceholder: 'Заполните поле',
        },
        errors: {
          '@any': 'Произошла ошибка!',
          '@extends': 'errors.validation',
        },
        types: {
          EMAIL: 'Электронная почта',
          PHONE: 'Телефон',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          WEB: 'Web',
        },
        submit: 'Добавить контакт',
        update: 'Обновление контакта',
      },
      paidServices: {
        title: 'Дополнительные услуги',
        active: 'Активный',
        label: 'Название кнопки',
      },
    },
    Clients: {
      title: 'Гости',
      visits: 'Количество посещений',
      cheque: 'Средний чек',
      addClient: 'Добавить',
      addNewClient: 'Добавление нового гостя',
      editingClient: 'Редактирование гостя',
      cancel: '<base.cancel>',
      save: '<base.save>',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.clients' },
      sort: {
        date: 'По дате добавления',
        cheque: 'По среднему чеку',
        visits: 'По колличеству посещений',
      },
      lang: { '@extends': 'lang' },
    },
    Pagination: {
      of: 'из',
    },
    SearchInput: {
      search: '<base.search>',
    },
    Administration: {
      title: 'Администрация',
      fields: { '@extends': 'types.admin' },
      addAdmin: 'Добавить администратора',
      addNewAdmin: 'Добавление администратора',
      editingAdmin: 'Редактирование администратора',
      cancel: '<base.cancel>',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.administrations' },
      deletingAdmin: 'Удаление администратора',
      confirmDeleteAdmin: 'Вы уверены, что хотите удалить данного администратора?',
    },
    Integrations: {
      telegramService: 'Telegram API',
      telegramKey: 'ID пользователя или группы в Telegram',
      googleService: 'Google My Business',
      googleKey: 'ID места',
      commentLanguage: 'Idioma de los comentarios',
      facebookService: 'Facebook Business Manager',
      facebookKey: 'Page Access Token',
      syrveService: 'Syrve',
      syrveKey: 'API ключ',
      syrveOrganization: 'Организация',
      syrveTerminal: 'Главный терминал',
      syrveApiFailed: 'Неправильный API ключ',
      loadSyrveMenu: 'Загрузить меню',
      portmoneService: 'Portmone',
      posterService: 'Poster',
      syncPosterTables : 'Синхронизация столов',
      synced: 'Столы синхронизированы',
      notSynced: 'Столы не синхронизированы',
      portmoneKey: 'Portmone Payee ID',
      posterClientId: 'Poser Client ID',
      connect: 'Подключить',
      reset: 'Очистить',
      loadSyrveOrganisations: 'Загрузить организацию',
      loadSyrveTerminals: 'Загрузить терминал',
      connected: 'Подключено',
      telegramConnectionFailed:
        'Не получилось отправить сообщение в чат с данным ID. Напишите @reservble_bot личное сообщение для получения индивидуальных уведомлений, или добавьте бота в группу для получения уведомлений в этой группе. После этого попробуйте снова.',
      googleApiFailed: 'Неверный ID места',
      enableBanquetPreOrder: 'Включить синхронизацию банкетов',
      enablePaymentsOrder: 'Включить оплату заказа стола',
      enableSyncReservations: 'Включить синхронизацию резервов',
      errors: { '@extends': 'errors.validation' },
      lang: { '@extends': 'lang' },
    },
    ReservationSettings: {
      title: 'Резервы',
      ourManagement: 'Не проверять наличие свободных мест при бронировании онлайн',
      autoSeating: 'Автоматическое подтверждение резервов',
      tgAdminNotification: 'Напоминание о пропущенных резервах',
      smsConfirmation: 'Отправлять смс уведомление о подтверждении резерва',
      smsFeedback: 'Отправлять смс с запросом на отзыв после успешного резерва',
      newReservationMessage: 'Отправлять сообщение в Telegram при создании нового резерва администратором',
      reservationCancelMessage: 'Отправлять сообщение в Telegram при отклонении или отмене бронирования',
      showHalls: 'Давать возможность гостям выбрать зал при бронировании онлайн',
      showSpots: 'Давать возможность гостям выбрать стол при бронировании онлайн',
      spotsMandatory: 'Сделать выбор стола обязательным',
      stepTimeSlots: 'Временной интервал между слотами бронирования',
      reservationDuration: 'Стандартная длительность резерва, минут',
      reservationAllDay: 'До конца дня',
      lastTimeReservation1: 'Принимать последний резерв за',
      lastTimeReservation2: 'минут до закрытия заведения',
      highlightReservations: 'Подсвечивать резервы перед началом',
      minutesBefore: 'За сколько минут',
      hideTableCapacity: 'Не показывать вместительность стола',
      disableSameTimeReservation: 'Не разрешать создавать резервы на одинаковое время',
      showAutomaticReservationButton: 'Показывать кнопку автоматического подбора стола',
      syrveDelay: 'После закрытия счета в Syrve, отмечать стол как доступный через',
      depositsActive: 'Включить депозиты',
      depositsPaymentTime: 'Время на оплату депозита, минут',
      onlyDepositReservations: 'Разрешать создавать резервы только с депозитами',
      menuActive: 'Включить меню',
      clientMenuActive: 'Также показывать меню на клиенте',
      certificatesActive: 'Показывать сертификаты на клиенте',
      customDeclineMsg: 'Собственное сообщение при отклонении резерва',
      minutes: 'минут',
      save: '<base.save>',
      success: '<actions.settings.saved>',
      title2: 'Дополнительная информация к резерву',
      customTagsTitle: 'Переопределение тегов',
      hourFields: { '@extends': 'types.placeOpenHours' },
      apply: 'Применить',
      message: {
        ruPlaceholder: 'Русский',
        uaPlaceholder: 'Українська',
        enPlaceholder: 'English',
        ruDefault: 'В связи с большой загрузкой действует ограничение в 2 часа на длительность резерва',
        uaDefault: "У зв'язку з великим завантаженням діє обмеження у 2 години на тривалість резерву",
        enDefault: 'Due to busy hours, there is a 2-hour limit for the reservations',
      },
      highloadTitle: 'Дополнительные опции',
      adminSelection: 'Выбирать администратора при создании резерва',
      showAllReservations: 'Разрешить показывать все резервы стола одновременно',
      showReservationConfirmed: 'Дополнительное подтверждение резервов',
      showWaitList: 'Показывать список ожидания',
      reservationMissed: 'Автоматически отменять резервы, если гость не пришел в течение',
      reservationMissedTime: 'минут',
      showHallFilter: 'Разрешать фильтровать резервы по залам',
      maxGuests: 'Максимальное количество гостей при бронировании',
      maxGuestsLabel: 'Введите количество',
    },
    BookingWidget: {
      organisationTitle: 'Рестораны сети',
      styleTitle: 'Настройки стилей',
      backgroundColor: 'Цвет фона',
      borderColor: 'Цвет границ',
      primaryColor: 'Основной цвет',
      primaryColorHover: 'Основной цвет при наводке',
      textColor: 'Цвет текста',
      contrastTextColor: 'Контрастный цвет текста',
      errorColor: 'Цвет ошибки',
      configTitle: 'Другие настройки',
      showSlots: 'Показывать доступные временные слоты',
      showHalls: 'Показывать залы для выбора',
      translations: 'Текстовые элементы',
      pageTitle: 'Заголовок страницы',
      policyTitle: 'Заголовок политики',
      policyText: 'Основной текст политики',
      policyItems: 'Элементы политики',
      finishTitle: 'Заголовок при завершении резерва',
      finishMessage: 'Сообщение при завершении резерва',
      save: '<base.save>',
      lang: { '@extends': 'lang' },
      success: '<actions.settings.saved>',
    },
    Deposits: {
      addDeposit: 'Добавить категорию',
      addType: 'Добавить период',
      active: 'Активная',
      depositPlaceholder: 'Название категории',
      typePlaceholder: 'Название периода',
      amountInUah: 'Укажите сумму депозита в заданный день в',
      amountInUahPlaceholder: 'Сумма в грн',
      uah: '<currencies.uah>',
      days: { '@extends': 'types.placeOpenHours' },
      save: '<base.save>',
      success: '<actions.settings.saved>',
      errors: { '@extends': 'errors.validation' },
      minOneType: 'Категория должна содержать хотя бы один период',
      deletingDeposit: 'Удаление категории',
      confirmDeleteDeposit: 'Вы уверены, что хотите удалить данную категорию? Это действие нельзя отменить',
      deletingType: 'Удаление периода',
      confirmDeleteType: 'Вы уверены, что хотите удалить данный период? Это действие нельзя отменить',
      disableQuantityLabel: 'Не показывать выбор количества для этой категории',
    },
    Promo: {
      title: 'Промоакции',
      current: 'Текущая акция',
      save: '<base.save>',
      noPromo: 'Отсутствует',
      percent: 'Процент',
      errors: { '@extends': 'errors.validation' },
      success: '<actions.settings.saved>',
    },
    Halls: {
      title: 'Залы заведения',
      addNewHall: 'Добавить зал',
      editHall: '<base.edit>',
      activeHall: 'Выбрать зал',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      spotsForm: {
        fields: { '@extends': 'types.placeSpot' },
        errors: {
          '@any': 'Произошла ошибка!',
          '@extends': 'errors.validation',
        },
        duplicate: 'Дублировать',
        delete: 'Удалить',
        submit: 'Создать стол',
        update: 'Обновление стола',
        backToHalls: 'Список залов',
        types: { ELLIPSE: 'Круглый', RECT: 'Прямоугольный', LOUNGE: 'Шезлонг' },
        ifZeroMinSeats: "Если 0 будет учитываться число из поля 'Кол-во Мест'",
        manualChairs: 'Ручная расстановка стульев',
        top: 'Сверху',
        bottom: 'Снизу',
        left: 'Слева',
        right: 'Справа',
        couch: 'Диван',
        active: 'Активный',
      },
      hallForm: {
        addNewHall: 'Добавление нового зала',
        editHall: 'Редактирование зала',
        fields: { '@extends': 'types.placeHall' },
        cancel: '<base.cancel>',
        save: '<base.save>',
        errors: { '@extends': 'errors.validation' },
        pattern: 'Фон зала',
        patterns: {
          none: 'Нет',
          asphalt: 'Асфальт',
          stone: 'Камень',
          tiles: 'Плитка',
          wood: 'Дерево',
        },
        bgCustom: 'Своя картинка',
      },
      reservationForm: {
        addingReservation: 'Создание резерва',
        editingReservation: 'Редактирование резерва',
        cancel: '<base.cancel>',
        save: '<base.save>',
        fields: {
          user: { '@extends': 'types.user' },
          reservation: { '@extends': 'types.reservation' },
        },
        errors: { '@extends': 'errors.validation' },
        pickSpot: 'Подобрать стол',
        spotNotFound: 'Свободный стол на выбранное время не найден',
        addToWaitList: 'Добавить в список ожидания',
        sameTimeReservationDisabled: 'Создание резервов на одинаковое время запрещено администратором',
        openMenu: 'Добавить блюда',
        certificateId: 'ID сертифіката/абонемента',
        findCertificate: 'Найти сертификат',
        messages: { '@extends': 'actions.certificates' }
      },
      deletingSpot: 'Удаление стола',
      confirmDeleteSpot: 'Вы уверены, что хотите удалить данный стол?',
      deletingHall: 'Удаление зала',
      confirmDeleteHall: 'Вы уверены, что хотите удалить данный зал? Все столы в нем таже будут удалены',
      accept: 'Подтвердить',
      assigningReservation: 'Назначение резерва',
      assignText: 'Вы уверены, что хотите подтвердить данный резерв и назначить его на выбранный столик?',
      reassignText: 'Вы хотите переместить данный резерв на другой стол, или присоединить еще один стол к резерву?',
      reassign: 'Переместить',
      link: 'Присоединить',
      cancel: '<base.cancel>',
      tableReservations: 'Резервы стола',
      close: '<base.close>',
      createReservation: 'Создать резерв',
      newWalkIn: 'Быстрая бронь',
      indicateSeats: 'Укажите количество человек',
      indicateFinish: 'Время окончания резерва',
      unknownUser: '<base.unknownUser>',
      showPast: 'Показывать завершенные резервы',
      hideReservations: 'Спрятать список резервов',
    },
    Profile: {
      title: 'Профиль пользователя',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.profile' },
      save: '<base.save>',
      logout: 'Закончить сессию',
    },
    PeriodsSelector: {
      select: {
        today: 'Сегодня',
        yesterday: 'Вчера',
        thisWeek: 'Эта неделя',
        lastWeek: 'Прошлая неделя',
        thisMonth: 'Этот месяц',
        lastMonth: 'Прошлый месяц',
        thisYear: 'Этот год',
        lastYear: 'Прошлый год',
      },
      orChoosePeriod: 'Или выберите период',
    },
    Reviews: {
      google: 'Отзывы Google',
      facebook: 'Отзывы на Facebook',
      reservble: 'Отзывы Reservble',
      seeAll: '<base.seeAll>',
      connectGoogle: 'Укажите ID места в Google My Business, чтобы видеть отзывы в Google',
      connectFacebook: 'Подключите Facebook Business Manager, чтобы видеть отзывы из Facebook',
      connect: 'Подключить',
      noReviews: 'Вы еще не получили отзывов здесь',
      expand: 'Развернуть',
      collapse: 'Свернуть',
      deleteReview: 'Удалить отзыв',
      deletingReview: 'Удаление отзыва',
      confirmDeleteReview: 'Вы уверены, что хотите удалить этот отзыв? Это действие невозможно отменить',
    },
    UserModal: {
      birthday: 'Дата рождения',
      gender: 'Пол',
      familyStatus: 'Семейное положение',
      comment: 'Комментарий',
      save: '<base.save>',
      preferences: 'Предпочтения',
      favorite: 'Любимые блюда',
      dontLike: 'Не нравится',
      allergies: 'Аллергии',
      cuisines: 'Любимые кухни',
      statistics: 'Статистика',
      ourPlace: 'Наше заведение',
      allPlaces: 'Все заведения',
      visits: 'Общее количество посещений',
      people: 'Среднее количество человек',
      averageBill: 'Средний чек',
      reservationHistory: 'История резервов',
      showAll: 'Показать все',
    },
    RestaurantStatistics: {
      '@extends': 'types.reservation',
      regularClients: 'Постоянные гости',
      total: 'Всего',
      regular: 'Постоянных',
      reviews: 'Отзывы <=service>',
      reviewsNumber: {
        1: 'отзыв',
        2: 'отзыва',
        5: 'отзывов',
      },
      details: 'Детально',
      onlineReservations: 'Онлайн резервов',
      handReservations: 'Резервы, введенные вручную',
      newUsers: 'Новые гости',
      chartTitle: 'Статистика ресторана',
    },
    Payment: {
      title: 'Оплата',
      pay: 'Пополнить баланс',
      currentBalance: 'Текущий баланс',
      balanceDetails:
        'Это ваш текущий баланс. Разбивка платежей за текущий расчетный период доступна ниже. Списание с баланса произойдет в конце текущего платежного периода',
      currentBillType: 'Текущий тариф',
      billingPeriod: 'Расчетный период',
      chatbot: 'Чат-бот в Facebook/Instagram',
      menu: 'Меню',
      tariffPayment: 'Базовая стоимость тарифа',
      reservationsInTariff: 'Резервов в тарифе',
      reservationsUsed: 'Резервов использовано',
      reservationsOverTariff: 'Оплата за дополнительные резервы',
      smsTotal: 'Оплата за смс',
      commission: 'Комиссия платежной системы (<=commission>%)',
      total: 'Сумма',
      paymentHistory: 'История оплат',
      invoice: 'Счет',
      payment: 'Оплата',
      invoiceDetails: 'Детали счета',
      invoicesHistory: 'История счета',
      invoiceBillType: 'Тариф',
      makingPayment: 'Пополнение баланса',
      selectAmount: 'Укажите сумму',
      orSelect: 'Или выберите период для пополнения',
      INPROCESS: 'К оплате',
      DONE: 'Уплачено',
      DONE_PAYMENT: 'Пополнено',
      months: {
        1: 'Один месяц',
        2: '2 месяца',
        3: '3 месяца',
        6: '6 месяцев',
        9: '9 месяцев',
        12: '12 месяцев',
      },
      proceed: 'Продолжить',
      errors: { '@extends': 'errors.validation' },
    },
    SuperAdmin: {
      menu: {
        organizations: 'Организации',
        places: 'Рестораны',
        countries: 'Страны',
        cities: 'Города',
        tags: 'Теги',
        invoices: 'Счета',
      },
      organizations: {
        addOrganization: 'Добавить организацию',
        title: 'Доступные организации',
        addingOrganization: 'Добавление организации',
        editingOrganization: 'Редактирование организации',
        deletingOrganization: 'Удаление организации',
        confirmDeleteOrganization: 'Вы уверены, что хотите удалить данную организацию из системы?',
        fields: {
          name: 'Название',
          created: 'Дата добавление',
        },
        errors: { '@extends': 'errors.validation' },
      },
      places: {
        withTarrifs: 'Только с тарифами',
        addRestaurant: 'Добавить ресторан',
        title: 'Доступные рестораны',
        activate: 'Активировать',
        deactivate: 'Деактивировать',
        addingRestaurant: 'Добавление ресторана',
        editingRestaurant: 'Редактирование ресторана',
        deletingRestaurant: 'Удаление ресторана',
        confirmDeleteRestaurant:
          'Вы уверены, что хотите удалить данный ресторан из системы? Это навсегда удалит все данные, связанные с ним.',
        activatingRestaurant: 'Активация ресторана',
        deactivatingRestaurant: 'Деактивация ресторана',
        confirmActivateRestaurant: 'Вы уверены, что хотите активировать данный ресторан?',
        confirmDeactivateRestaurant:
          'Вы уверены, что хотите деактивировать данный ресторан? Он больше не будет показываться на гостевом сайте, а все аккаунты, связанные с ним, будут временно заблокированы',
        fields: {
          name: 'Название',
          ownerName: 'Имя владельца',
          ownerLastName: 'Фамилия владельца',
          ownerPhone: 'Телефон владельца',
          active: 'Активный',
          billType: 'Тариф',
          created: 'Дата добавления',
          charityBill: 'Сумма чеков',
          organization: 'Организация',
        },
        sort: {
          date: 'По дате добавления',
          name: 'По имени',
        },
        errors: { '@extends': 'errors.validation' },
      },
      countries: {
        addCountry: 'Добавить страну',
        title: 'Доступные страны',
        addingCountry: 'Добавление страны',
        editingCountry: 'Редактирование страны',
        fields: {
          id: 'ID',
          name: "Імя",
          lang: "Язык",
        },
        lang: { '@extends': 'lang' },
        errors: { '@extends': 'errors.validation' },
      },
      cities: {
        addCity: 'Добавить город',
        title: 'Доступные города',
        addingCity: 'Добавление города',
        editingCity: 'Редактирование города',
        fields: {
          id: 'ID',
          code: 'Имя',
          country: "Страна",
          timezone: "Таймзона",
        },
        errors: { '@extends': 'errors.validation' },
      },
      tags: {
        addTag: 'Добавить тег',
        title: 'Доступные теги',
        addingTag: 'Добавление тега',
        editingTag: 'Редактирование тега',
        fields: {
          id: 'ID',
          name: 'Имя',
          type: 'Тип',
          search: 'Поиск',
        },
        errors: { '@extends': 'errors.validation' },
      },
      invoices: {
        title: 'Новые счета',
        approve: 'Подтвердить',
        approvingInvoices: 'Подтверждение счетов',
        confirmApproveInvoices: 'Вы уверены, что хотите подтвердить все выбранные счета?',
        editingInvoice: 'Редактирование счета',
        fields: {
          id: 'ID',
          place: 'Ресторан',
          date: 'Дата',
          amount: 'Сумма',
          adjustment: 'Корректировка',
          status: 'Статус',
          comment: 'Комментарий',
          confirm: '<base.confirm>'
        },
        errors: { '@extends': 'errors.validation' },
      },
    },
    NoAccess: {
      message: 'Ваш текущий тариф не включает доступ к данной странице. Для смены тарифа обратитесь к ',
      admin: 'администратору',
    },
    ReservationHistoryModal: {
      close: '<base.close>',
      title: 'История изменений резерва',
      date: 'Дата',
      time: 'Время',
      user: 'Пользователь',
      action: 'Действие',
      guest: '<base.unknownUser>',
      new: 'Резерв создан',
      billRemoved: 'Удален депозит',
      changed: 'Резерв изменен',
      status: {
        REQUESTED: 'Гость создал новый резерв',
        ACCEPTED: 'Резерв подтвержден',
        INPROGRESS: 'Гость пришел в ресторан',
        REJECTED: 'Резерв отказан администратором',
        FINISHED: 'Резерв отмечен как завершенный',
        SKIPPED: 'Гость не пришел',
        CANCELED: 'Резерв отменен гостем',
      },
      fields: { '@extends': 'types.reservation' },
    },
    Dishes: {
      title: 'Меню',
      addCategory: 'Добавить категорию',
      edit: '<base.edit>',
      delete: '<base.delete>',
      addDish: 'Добавить блюдо',
      uploadPhoto: 'Загрузить фотографию',
      addingCategory: 'Добавление категории',
      editingCategory: 'Редактирование категории',
      addingDish: 'Добавление блюда',
      editingDish: 'Редактирование блюда',
      deletingCategory: 'Удаление категории',
      confirmDeleteCategory: 'Вы уверены, что хотите удалить данную категорию из меню?',
      deletingDish: 'Удаление блюда',
      confirmDeleteDish: 'Вы уверены, что хотите удалить данное блюдо? Оно больше не будет доступно',
      uah: '<currencies.uah>',
      category: {
        '@extends': 'types.dishCategory',
      },
      dish: {
        '@extends': 'types.dish',
      },
      errors: {
        '@extends': 'errors.validation',
      },
    },
    ConflictingReservationsModal: {
      title: 'На данном столе есть другой резерв',
      cancel: '<base.cancel>',
      confirm: '<base.confirm>',
      description:
        'Вы уверены, что хотите назначить резерв на данный стол и указанные дату и время? Данный слот уже занят следующими резервами:',
    },
    OverduePaymentPopup: {
      message: 'Заведение имеет неоплаченный счет. Пополните баланс на странице оплаты',
    },
    Hours: {
      notWorkingDays: 'Нерабочие дни',
      workingDays: 'Рабочие дни',
      selectDate: 'Выберите дату',
      addDate: 'Добавить дату',
      dateList: 'Список нерабочих дней',
      dateOpenList: 'Список рабочих дней',
    },
    DepositManagement: {
      switch: 'Депозит',
      total: 'Общая сумма',
      quantity: 'Кол-во',
      price: 'Цена',
      uah: '<currencies.uah>',
    },
    PaymentStatistics: {
      depositsTab: 'Депозиты',
      certificatesTab: 'Сертификаты',
      deposits: {
        name: 'Имя',
        phone: 'Номер телефона',
        hall: 'Зал',
        spot: 'Стол',
        billId: 'ID платежа',
        amount: 'Сумма',
        total: 'Общая сума',
      },
      certificates: {
        '@extends': 'types.certificate',
        firstName: 'Имя',
        phone: 'Номер телефона',
        price: '<types.certificate.price>',
        name: '<types.certificate.name>',
        id: 'Уникальный номер',
        date: 'Дата покупки',
        total: 'Общая сума',
      },
      uah: '<currencies.uah>',
    },
    MenuModal: {
      title: 'Меню',
      search: 'Введите название блюда',
      uah: '<currencies.uah>',
      cancel: '<base.cancel>',
      save: 'Добавить к резерву',
      preview: 'Просмотр',
      total: 'Общая сумма',
      prepayment: 'Сумма предоплаты',
    },
    PreviewMenuModal: {
      title: 'Блюда добавленные к заказу',
      total: 'Общая сумма',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
    },
    DishCard: {
      uah: '<currencies.uah>',
      qty: 'Кол-во',
    },
    DishCategoriesMenu: {
      edit: '<base.edit>',
      delete: '<base.delete>'
    },
    CertificateSettings: {
      addCertificate: 'Создать сертификат',
      addingCertificate: 'Добавление сертификата',
      editingCertificate: 'Редактирование сертификата',
      deletingCertificate: 'Удаление сертификата',
      confirmDeleteCertificate: 'Вы уверены, что хотите удалить этот сертификат? Он будет недоступен в будущем',
      uploadPhoto: 'Загрузить фотографию',
      fields: { '@extends': 'types.certificate', },
      errors: { '@extends': 'errors.validation' },
    },
    CertificateSettingsCard: {
      '@extends': 'types.certificate',
      active: 'Активный',
      inactive: 'Неактивный',
      days: 'дней',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
      delete: '<base.delete>',
    },
    Certificates: {
      title: 'Сертифіикаты',
      addCertificate: 'Добавить сертификат',
      addNewCertificate: 'Добавление нового сертификата',
      certificateDetails: 'Информация про сертификат',
      cancel: '<base.cancel>',
      save: '<base.save>',
      delete: '<base.delete>',
      fields: { '@extends': 'types.certificate' },
      uah: '<currencies.uah>',
      days: 'дней',
      visits: 'посещений',
      activate: 'Активировать',
      sendAgain: '<base.sendAgain>',
      sms: 'СМС сообщение',
      smsSent: 'Отправлено',
      smsNotSent: 'Не отправлено',
      deletingCertificate: 'Удаление сертификата',
      confirmDeleteCertificate: 'Вы уверены, что хотите удалить этот сертификат? Данное действие нельзя отменить',
      paid: 'Оплачено наличными',
      messages: { '@extends': 'actions.certificates' },
      errors: { '@extends': 'errors.validation' },
      filter: {
        certificates: 'Сертификаты',
        subscriptions: 'Абонементы',
      },
    },
  },
  actions: {
    place: {},
    hall: {
      saved: 'Изменения сохранены',
      hallAdded: 'Зал успешно добавлен',
      spotDeleted: 'Стол удален',
    },
    settings: {
      saved: 'Настройки сохранены',
      menuConnected: 'Меню успешно загружено',
    },
    clients: {
      added: 'Гость добавлен',
      exist: 'Пользователь добавлен под именем <=displayedName>',
    },
    administrations: {
      added: 'Пользователь добавлен',
      exist: 'Пользователь добавлен под именем <=displayedName>',
      edited: 'Права пользователя отредактированы',
      deleted: 'Права пользователя удалены',
    },
    profile: {
      saved: 'Изменения сохранены',
      passwordChanged: 'Пароль был успешно изменен',
      oldPasswordIsInvalid: 'Старый пароль не верен',
    },
    reservation: {
      success: 'Резерв обновлен успешно',
      alreadyBookedTable: 'У нас уже есть заказ на это время за этим столом',
      busySlotForReservation: 'Создание резервов на одинаковое время запрещено администратором',
    },
    certificates: {
      added: 'Сертификат успешно создан',
      saved: 'Изменения сохранены',
      smsResent: 'СМС повторно отправлено',
      notFound: 'Сертификат с таким номером не найден',
    },
  },
  service: {
    jwt: {
      invalidToken: 'Недопустимый токен доступа',
      accessExpired: 'Токен доступа истек',
      signinInProcess: 'Авторизация в процессе...',
      welcomeMessage: 'Добро пожаловать в RESERVBLE Resto UI',
      loginWithTokenFailed: 'Войти по токену не удалось',
    },
  },
};
