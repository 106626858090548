import React, { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { default as MuiSelect } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { isDefined } from 'utils/lo/lo';
import mova from 'mova';

const t = mova.ns('components.Select')

const StyledSelect = styled(MuiSelect, { shouldForwardProp: prop => prop !== 'bold' })(({ theme, type, bold, error }) => ({
  maxWidth: '100%',
  borderRadius: theme.shape.borderRadiusSmall,
  backgroundColor:
    type === 'red' ? theme.palette.secondary.main : type === 'white' ? theme.palette.common.white : 'transparent',
  color: type === 'red' ? theme.palette.common.white : theme.palette.typography.primary,
  fontWeight: bold ? 600 : 400,
  '.MuiSelect-select': {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  '.MuiSelect-icon': {
    color: type === 'red' ? theme.palette.common.white : theme.palette.typography.primary,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px !important',
    borderColor: error
      ? theme.palette.secondary.main
      : type === 'red' ? 'transparent !important' : theme.palette.typography.light + ' !important',
  },
  '&:hover': {
    backgroundColor:
      type === 'red' ? theme.palette.secondary.light : type === 'white' ? theme.palette.common.white : 'transparent',
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  ':not(.MuiInputLabel-shrink)': {
    top: -6,
  },
}));

const Select = (
  {
    label,
    value,
    onChange,
    items,
    bold,
    sendEvent = false,
    labelPath,
    valuePath,
    disabled = false,
    children,
    selectAll,
    error,
    wrapperProps = {},
    ...props
  },
) => {
  const theme = useTheme();
  const menuItems = items?.map(i => (
    <MenuItem sx={{ height: theme.spacing(5) }} key={i[valuePath]} value={i[valuePath]}>
      {i[labelPath]}
    </MenuItem>
  ));

  const handleChange = e => {
    let val = e.target.value;
    if (Array.isArray(val) && val.at(-1) === "select-all") {
      val = value.length === items.length ? [] : items.map(i => i[valuePath]);
      e.target.value = val;
    }

    if (sendEvent) {
      onChange(e);
    } else {
      onChange(val);
    }
  };

  if (selectAll) {
    menuItems.unshift((
      <MenuItem sx={{ height: theme.spacing(5) }} key='select-all' value='select-all'>
        {t('selectAll')}
      </MenuItem>
    ));
  }

  const select = (
    <StyledSelect
      value={value}
      label={label}
      onChange={handleChange}
      bold={bold}
      disabled={disabled}
      error={error}
      {...props}
    >
      {children || menuItems}
    </StyledSelect>
  );

  return (
    <Box {...wrapperProps}>
      {label ? (
        <FormControl fullWidth disabled={disabled} error={error}>
          <StyledInputLabel>{label}</StyledInputLabel>
          {select}
        </FormControl>
      ) : (
        select
      )}
    </Box>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  onChange: PropTypes.func,
  itemsLabel: (props) => {
    if (props.items?.some(i => !isDefined(i[props.labelPath]))) {
      return new Error(`Items in select are incorrect - some of them is missing ${props.labelPath} prop`);
    }
  },
  itemsValue: (props) => {
    if (props.items?.some(i => !isDefined(i[props.valuePath]))) {
      return new Error(`Items in select are incorrect - some of them is missing ${props.valuePath} prop`);
    }
  },
  bold: PropTypes.bool,
  type: PropTypes.oneOf(['red', 'white']),
};

Select.defaultProps = {
  labelPath: 'label',
  valuePath: 'value',
};

export default memo(Select);
